import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components'; 

// Same keyframes as in NotificationsModal
const slideIn = keyframes`
  0% {
    transform: translateX(100%); 
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0); 
    opacity: 1;
  }
  100% {
    transform: translateX(100%); 
    opacity: 0;
  }
`;

// Container with conditional animation based on isClosing prop
const GiftsModalContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 5px;
  background-color: white;
  width: 450px;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px solid #8A3518;
  z-index: 100;
  animation: ${props => (props.isClosing ? slideOut : slideIn)} 0.4s ease-out;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #8A3518;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const GenerateButton = styled.button`
  width: 100%;
  background-color: #8A3518;
  color: white;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #7A2F16;
  }
`;

const GiftsModal = ({ onClose }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  // Fetch products from API on mount
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.hikikorp.it/products'); // Replace with your API URL
        const data = await response.json();
        setProducts(data); 
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Close handler with animation
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 400); 
  };

  // Generate code handler
  const handleGenerate = () => {
    if (selectedProduct) {
      console.log(`Generating code for product: ${selectedProduct}`);
      // Add logic here to generate the code for the selected product
    } else {
      alert('Please select a product!');
    }
  };

  return (
    <GiftsModalContainer isClosing={isClosing}>
      <CloseButton onClick={handleClose}>
        Segna tutto come letto
      </CloseButton>

      <Dropdown 
        value={selectedProduct} 
        onChange={(e) => setSelectedProduct(e.target.value)}
      >
        <option value="" disabled>Select a product</option>
        {products.map((product) => (
          <option key={product.id} value={product.id}>
            {product.product_name} ({product.product_price} €)
          </option>
        ))}
      </Dropdown>

      <GenerateButton onClick={handleGenerate}>
        Generate Code
      </GenerateButton>
    </GiftsModalContainer>
  );
};

export default GiftsModal;