import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { validateToken } from './auth';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); 
  const token = localStorage.getItem('token');

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!token) {
        setIsAuthenticated(false);
        return;
      }
      const isValid = await validateToken(token);
      setIsAuthenticated(isValid);
    };

    checkAuthentication();
  }, [token]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; 
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;