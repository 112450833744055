import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { jwtDecode } from "jwt-decode";
import SideBar from './_components/SideBar';
import { validateToken } from '../../../auth/auth';
import TopBar from './_components/TopBar';

const AdminPageContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  flex: 1; 
  display: flex;
  flex-direction: column;
`;

const AdminPage = () => {
  const [decodedToken, setDecodedToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');

      const isValid = await validateToken(token);

      if (!isValid) {
        console.error('Invalid or expired token');
        localStorage.removeItem('token');
        navigate('/');
      } else {
        try {
          const decoded = jwtDecode(token);
          setDecodedToken(decoded);
        } catch (error) {
          console.error('Failed to decode token', error);
        }
      }
    };

    checkToken();
  }, [navigate]);

  return (
    <AdminPageContainer>
      <SideBar />
      <MainContent>
        <TopBar />
      </MainContent>
    </AdminPageContainer>
  );
};

export default AdminPage;