import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components'; // Import keyframes for animation
import { FaCheckDouble } from 'react-icons/fa';  // Import double tick icon
import ReactMarkdown from 'react-markdown';

// Create keyframes for the slide-in animation
const slideIn = keyframes`
  0% {
    transform: translateX(100%); // Start the modal off the screen to the right
    opacity: 0;
  }
  100% {
    transform: translateX(0); // Bring it to its normal position
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0); // Start from its normal position
    opacity: 1;
  }
  100% {
    transform: translateX(100%); // Slide it out to the right
    opacity: 0;
  }
`;

const NotificationsModalContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 5px;
  background-color: white;
  width: 450px;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px solid #8A3518;
  z-index: 100;
  animation: ${props => (props.isClosing ? slideOut : slideIn)} 0.4s ease-out; // Use slideOut when closing and slideIn when opening
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
`;

const MarkAllRead = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #8A3518;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)}; 

  svg {
    margin-right: 5px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #f1f1f1;
  padding: 5px 8px;
  border-radius: 10px;
  margin-bottom: 15px;
  white-space: nowrap;
`;

const Tab = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${props => (props.active ? '#8A3518' : '#888')};
  background-color: ${props => (props.active ? '#fff' : 'transparent')};
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => (props.active ? '#ddd' : 'transparent')};
  box-shadow: ${props => (props.active ? '0px 2px 5px rgba(0,0,0,0.1)' : 'none')};
  height: 25px;
  min-width: 80px;
  transition: all 0.3s ease;
`;

const TabCount = styled.div`
  margin-left: 6px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${props => (props.active ? '#F1C6A6' : '#D3D3D3')};
  border-radius: 4px;
  padding: 2px 5px;
  min-width: 15px; 
  text-align: center;
  transition: background-color 0.3s ease;
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const NotificationContent = styled.div`
  flex: 1;
`;

const NotificationText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #333;
`;

const Timestamp = styled.p`
  font-size: 12px;
  color: #888;
  margin: 5px 0 0;
`;

const NoNotifications = styled.p`
  text-align: center;
  margin-top: 20px; 
  font-size: 14px;
  color: #888;
`;

const NotificationsModal = ({ mentions = [], followers = [], invites = [], onClose, userId, fetchNotifications }) => {
  const [activeTab, setActiveTab] = useState('all');
  const [isClosing, setIsClosing] = useState(false);

  const allNotifications = [...mentions, ...followers, ...invites];

  const handleMarkAllRead = () => {

    if (allNotifications.length === 0) {
      return;
    }

    fetch(`https://api.hikikorp.it/notifications/${userId}/read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ mentions, followers, invites }), 
    })
      .then((response) => response.json())
      .catch((error) => console.error('Error marking notifications as read:', error));

      fetchNotifications();
      setIsClosing(true); 
      setTimeout(onClose, 400); 
  };

  const renderNotifications = () => {

    const formatTimestamp = (timestamp) => {
      const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
      const date = new Date(timestamp);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const isToday = date.toDateString() === today.toDateString();
      const isYesterday = date.toDateString() === yesterday.toDateString();

      const formattedTime = date.toLocaleString('it-IT', {
        hour: '2-digit',
        minute: '2-digit',
      });

      const formattedDate = date.toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });

      if (isToday) {
        return `Oggi, ${formattedDate}, ${formattedTime}`;
      } else if (isYesterday) {
        return `Ieri, ${formattedDate}, ${formattedTime}`;
      } else {
        const dayOfWeek = daysOfWeek[date.getDay()];
        return `${dayOfWeek}, ${formattedDate}, ${formattedTime}`;
      }
    };


    switch (activeTab) {
      case 'mentions':
        return mentions.length > 0 ? mentions.map((notification, index) => (
          <NotificationItem key={index}>
            <ProfileImage src={notification.profilePhoto} alt="Profile" />
            <NotificationContent>
              <NotificationText><strong>{notification.title}</strong></NotificationText>
              <NotificationText>{notification.message}</NotificationText>
              <Timestamp>{formatTimestamp(notification.timestamp)}</Timestamp>
            </NotificationContent>
          </NotificationItem>
        )) : <NoNotifications>Non hai nuove notifiche!</NoNotifications>;

      case 'followers':
        return followers.length > 0 ? followers.map((notification, index) => (
          <NotificationItem key={index}>
            <ProfileImage src={notification.profilePhoto} alt="Profile" />
            <NotificationContent>
              <NotificationText><strong>{notification.title}</strong></NotificationText>
              <NotificationText>{notification.message}</NotificationText>
              <Timestamp>{formatTimestamp(notification.timestamp)}</Timestamp>
            </NotificationContent>
          </NotificationItem>
        )) : <NoNotifications>Non hai nuove notifiche!</NoNotifications>;

      case 'invites':
        return invites.length > 0 ? invites.map((notification, index) => (
          <NotificationItem key={index}>
            <ProfileImage src={notification.profilePhoto} alt="Profile" />
            <NotificationContent>
              <NotificationText><strong>{notification.title}</strong></NotificationText>
              <NotificationText>{notification.message}</NotificationText>
              <Timestamp>{formatTimestamp(notification.timestamp)}</Timestamp>
            </NotificationContent>
          </NotificationItem>
        )) : <NoNotifications>Non hai nuove notifiche!</NoNotifications>;

      case 'all':
      default:
        return allNotifications.length > 0 ? allNotifications.map((notification, index) => (
          <NotificationItem key={index}>
            <ProfileImage src={notification.profilePhoto} alt="Profile" />
            <NotificationContent>
              <NotificationText><strong>{notification.title}</strong></NotificationText>
              <NotificationText>{notification.message}</NotificationText>
              <Timestamp>{formatTimestamp(notification.timestamp)}</Timestamp>
            </NotificationContent>
          </NotificationItem>
        )) : <NoNotifications>Non hai nuove notifiche!</NoNotifications>;
    }
  };

  return (
    <NotificationsModalContainer isClosing={isClosing}>
      <Header>
        <Title>Le tue notifiche</Title>
        <MarkAllRead disabled={allNotifications.length === 0} onClick={handleMarkAllRead}>
          <FaCheckDouble /> Segna tutto come letto
        </MarkAllRead>
      </Header>

      <TabsContainer>
        <Tab active={activeTab === 'all'} onClick={() => setActiveTab('all')}>
          Vedi tutte
          <TabCount active={activeTab === 'all'}>
            {mentions.length + followers.length + invites.length}
          </TabCount>
        </Tab>
        <Tab active={activeTab === 'mentions'} onClick={() => setActiveTab('mentions')}>
          Menzioni
          <TabCount active={activeTab === 'mentions'}>{mentions.length}</TabCount>
        </Tab>
        <Tab active={activeTab === 'followers'} onClick={() => setActiveTab('followers')}>
          Followers
          <TabCount active={activeTab === 'followers'}>{followers.length}</TabCount>
        </Tab>
        <Tab active={activeTab === 'invites'} onClick={() => setActiveTab('invites')}>
          Inviti
          <TabCount active={activeTab === 'invites'}>{invites.length}</TabCount>
        </Tab>
      </TabsContainer>

      {renderNotifications()}
    </NotificationsModalContainer>
  );
};

export default NotificationsModal;